import React from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const NavbarComponent = () => {
    return (
        <Navbar expand="lg" style={{ backgroundColor: "#5c0f89" }}>
          <Container fluid >
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                      <Button variant="outline-light" href="#home" size="lg" className="m-1" >ABOUT US</Button>
                      <Button variant="outline-light" href="#link" size="lg" className="m-1">WHY USE US</Button>                    
                      <Button variant="outline-light" href="#link" size="lg" className="m-1">HOW IT WORKS</Button>                    
                      <Button variant="outline-light" href="#link" size="lg" className="m-1">FAQ</Button>                    
                  </Nav>
              </Navbar.Collapse>
          </Container>
      </Navbar>
    );
}

export default NavbarComponent;