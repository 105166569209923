// src/Layouts/MainLayout/MainLayout.js
import React from 'react';
import './MainLayout.css';
import Image from '../../Assets/images/feature-image.jpg'
import NavbarComponent from '../../Components/NavbarComponent/NavbarComponent';
import FooterComponent from '../../Components/FooterComponent/FooterComponent';

const MainLayout = ({children}) => {
  return (
    <div className='layout-container'>
      <div id='navDiv'>
        <NavbarComponent />
      </div>
      <div className="content">
        <div className="left-col">
          <img src={Image} alt="Feature" />
        </div>
        <div className="right-col">
          {children}
        </div>
      </div>
      <div id='footerDiv'>
        <FooterComponent />
      </div>
      
    </div>
  );
}

export default MainLayout;
