import React from "react";

const FooterComponent = () => {
    return (
        <footer className="p-2" style={{ backgroundColor: "#111111" }}>
            <div className="row">
                <div className="col-12">
                    <small>
                        <p className="text-center" style={{ color: "#ffffff" }}>&copy; 2024. iMasFinance Co-op Ltd (1996/000001/24) is a registered credit provider | NCRCP 1332 and Iemas Insurance Brokers (Pty) Ltd, an authorised FSP (FSP 47563), t/a iMas Insurance Brokers.</p>
                    </small>
                </div>
            </div>
        </footer>
        
    );
}

export default FooterComponent;