import React, { useState, useEffect } from 'react';

const RemindFormComponent = () => {
    const [step, setStep] = useState(1); // Step state to track the current form step
    const [vehicles, setVehicles] = useState([]);
    const [currentVehicle, setCurrentVehicle] = useState({ 
        registration: '', 
        licenseDisc: null, 
        licenseDiscURL: '', 
        detailsCaptured: false, 
        make: '', 
        model: '', 
        year: '', 
        month: '', 
        province: '', 
        tare: '' 
    });
    const [captureMode, setCaptureMode] = useState(false);
    const [personalDetails, setPersonalDetails] = useState({ firstName: '', lastName: '', cellNo: '', email: '' });

    useEffect(() => {
        if (currentVehicle.licenseDisc) {
            const fileURL = URL.createObjectURL(currentVehicle.licenseDisc);
            setCurrentVehicle(prev => ({ ...prev, licenseDiscURL: fileURL }));
            return () => URL.revokeObjectURL(fileURL); // Clean up object URL on unmount
        }
    }, [currentVehicle.licenseDisc]);

    const handleInputChange = (e) => {
        setCurrentVehicle({ ...currentVehicle, [e.target.name]: e.target.value });
    };

    const handlePersonalDetailsChange = (e) => {
        setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
    };

    const handleFileUpload = (e) => {
        setCurrentVehicle({
            ...currentVehicle,
            licenseDisc: e.target.files[0],
            licenseDiscURL: URL.createObjectURL(e.target.files[0]), // Set file URL
            detailsCaptured: false
        });
        setCaptureMode(false);
    };

    const handleCaptureDetails = () => {
        setCaptureMode(true);
        setCurrentVehicle({ ...currentVehicle, detailsCaptured: true, licenseDisc: null, licenseDiscURL: '' });
    };

    const addVehicle = () => {
        if (currentVehicle.registration) {
            setVehicles([...vehicles, currentVehicle]);
            setCurrentVehicle({ registration: '', licenseDisc: null, licenseDiscURL: '', detailsCaptured: false, make: '', model: '', month: '', year: '', province: '', tare: '' });
            setCaptureMode(false);
        }
    };

    const editVehicle = (index) => {
        setCurrentVehicle(vehicles[index]);
        setVehicles(vehicles.filter((_, i) => i !== index));
        setCaptureMode(false);
        setStep(2); // Go back to the vehicle details step
    };

    const removeVehicle = (index) => {
        setVehicles(vehicles.filter((_, i) => i !== index));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission with all vehicle details.
        console.log('Form submitted with the following data:', { personalDetails, vehicles });
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 20;
    const maxYear = currentYear + 1;

    return (
        <>
            <h4 className="py-2">Remind me to renew my license</h4>

            <form onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <h5 className='py-3'>Step 1: Personal Details</h5>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="firstName" 
                                    name="firstName" 
                                    value={personalDetails.firstName} 
                                    onChange={handlePersonalDetailsChange} 
                                    placeholder="e.g. John" 
                                    required 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="lastName" 
                                    name="lastName" 
                                    value={personalDetails.lastName} 
                                    onChange={handlePersonalDetailsChange} 
                                    placeholder="e.g. Smith" 
                                    required 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="cellNo" className="form-label">Mobile Number</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="cellNo" 
                                    name="cellNo" 
                                    value={personalDetails.cellNo} 
                                    onChange={handlePersonalDetailsChange} 
                                    placeholder="e.g. 0821234567" 
                                    required 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="email" className="form-label">Email Address</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="email" 
                                    name="email" 
                                    value={personalDetails.email} 
                                    onChange={handlePersonalDetailsChange} 
                                    placeholder="e.g. john.smith@mail.com" 
                                />
                            </div>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
                    </>
                )}

                {step === 2 && (
                    <>
                        <h5 className='py-3'>Step 2: Vehicle Details</h5>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="registration" className="form-label">License Number</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="registration" 
                                    name="registration" 
                                    value={currentVehicle.registration} 
                                    onChange={handleInputChange} 
                                    placeholder='e.g. CAA12345' 
                                    required 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label htmlFor="licenseDisc" className="form-label">Upload License Disc</label>
                            </div>
                            <div className="col-8">
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    id="licenseDisc" 
                                    onChange={handleFileUpload} 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <label className="form-label">Or Capture Details</label>
                            </div>
                            <div className="col-8">
                                <button type="button" className="btn btn-secondary" onClick={handleCaptureDetails}>Capture Details</button>
                            </div>
                        </div>

                        {captureMode && (
                            <div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="make" className="form-label">Vehicle Make</label>
                                    </div>
                                    <div className="col-8">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="make" 
                                            name="make" 
                                            value={currentVehicle.make} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="model" className="form-label">Vehicle Model</label>
                                    </div>
                                    <div className="col-8">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="model" 
                                            name="model" 
                                            value={currentVehicle.model} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="province" className="form-label">License Province</label>
                                    </div>
                                    <div className="col-8">
                                        <select 
                                            type="text" 
                                            className="form-select" 
                                            id="province" 
                                            name="province" 
                                            value={currentVehicle.province} 
                                            onChange={handleInputChange} 
                                            required
                                        >
                                            <option value="#">Select Province</option>
                                            <option value="EC">Eastern Cape</option>
                                            <option value="FS">Free State</option>
                                            <option value="GP">Gauteng</option>
                                            <option value="KZN">KwaZulu-Natal</option>
                                            <option value="LP">Limpopo</option>
                                            <option value="MP">Mpumalanga</option>
                                            <option value="NC">Northern Cape</option>
                                            <option value="NW">North West</option>
                                            <option value="WC">Western Cape</option>  
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="tare" className="form-label">Vehicle tare</label>
                                    </div>
                                    <div className="col-8">
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="tare" 
                                            name="tare" 
                                            value={currentVehicle.tare} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="date" className="form-label">License Expiry Date</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="input-group">
                                            <select 
                                                name="month" 
                                                id="month" 
                                                className="form-select" 
                                                value={currentVehicle.month} 
                                                onChange={handleInputChange} 
                                                required
                                            >
                                                <option>Select Month</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                            </select>
                                            <select 
                                                name="year" 
                                                id="year" 
                                                className="form-select" 
                                                value={currentVehicle.year} 
                                                onChange={handleInputChange} 
                                                required
                                            >
                                                <option>Select Year</option>
                                                {[...Array(maxYear - minYear + 1).keys()].map(i => (
                                                    <option key={i} value={maxYear - i}>{maxYear - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mb-3">
                            <button type="button" className="btn btn-warning" onClick={prevStep}>Previous</button>{' '}
                            <button type="button" className="btn btn-success" onClick={addVehicle}>Add Vehicle</button>{' '}
                            <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                        <h5 className='py-3'>Final Step: Review and Submit</h5>
                        <div className="mb-3">
                            <h6>Personal Details:</h6>
                            <p>First Name: {personalDetails.firstName}</p>
                            <p>Last Name: {personalDetails.lastName}</p>
                            <p>Mobile Number: {personalDetails.cellNo}</p>
                            <p>Email: {personalDetails.email}</p>
                        </div>

                        <div className="mb-3">
                            <h6 className='mt-5'>Vehicle Details:</h6>
                            {vehicles.length > 0 && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Vehicle</th>
                                            <th>License No.</th>
                                            <th>Make & Model</th>
                                            <th>Province</th>
                                            <th>Tare (kg)</th>
                                            <th>Expire</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehicles.map((vehicle, index) => (
                                            vehicle.detailsCaptured ? (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{vehicle.registration}</td>
                                                    <td>{vehicle.make}{' '}{vehicle.model}</td>
                                                    <td>{vehicle.province}</td>
                                                    <td>{vehicle.tare}</td>
                                                    <td>{vehicle.month}{' '}{vehicle.year}</td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={() => editVehicle(index)}>EDIT</button>{' '}
                                                        <button className="btn btn-danger" onClick={() => removeVehicle(index)}>REMOVE</button>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr key={index}>
                                                    <td> {index + 1}</td>
                                                    <td>{vehicle.registration}</td>
                                                    <td colSpan={5}>License Disc Uploaded: Yes</td>
                                                    
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                </table>
                                
                            )}
                        </div>
                        <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>{' '}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </>
                )}
            </form>
        </>
    );
};

export default RemindFormComponent;