import React from "react";

const RenewFormComponent = () => {
    

    return (
        <>
            <h4 className="py-2">Renew my License</h4>
            
        </>
    );
};

export default RenewFormComponent;
