import React, { useState } from "react";
import './HomePage.css';
import Logo from '../../Logo.jpg';
import RenewFormComponent from '../../Components/RenewFormComponent/RenewFormComponent';
import RemindFormComponent from "../../Components/RemindFormComponent/RemindFormComponent";

const HomePage = () => {
    const [selectedOption, setSelectedOption] = useState(''); // State to track the selected option

    // Event handler to update the selected option
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.id);
    };

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <img src={Logo} alt="Logo" style={{ width: "40%" }} />
            </div>
            <h3 className="py-2">Hi! Let's get started.</h3>
            <div className="d-flex" style={{ backgroundColor: '#f1f1f1' }}>
                <div className="p-2 flex-fill">
                    <div className="form-check">
                        <input type="radio" className="form-check-input" name="flexRadioInput" id="radioInputRemind" onChange={handleOptionChange} />
                        <label htmlFor="radioInputRemind" className="form-check-label">Remind Me</label>
                    </div>
                </div>
                <div className="p-2 flex-fill">
                    <div className="form-check">
                        <input type="radio" className="form-check-input" name="flexRadioInput" id="radioInputRenew" onChange={handleOptionChange} />
                        <label htmlFor="radioInputRenew" className="form-check-label">Renew License</label>
                    </div>
                </div>
                <div className="p-2 flex-fill">
                    <div className="form-check">
                        <input type="radio" className="form-check-input" name="flexRadioInput" id="radioInputStatus" onChange={handleOptionChange} />
                        <label htmlFor="radioInputStatus" className="form-check-label">Check Status</label>
                    </div>
                </div>
            </div>
        
         {/* Conditionally render components based on the selected option */}
         <div className="py-2">
            {selectedOption === 'radioInputRemind' && <RemindFormComponent />}
            {selectedOption === 'radioInputRenew' && <RenewFormComponent />}
            {selectedOption === 'radioInputStatus' }
        </div>
        
        </div>
    );
};

export default HomePage;